import { breakpoints } from "theme/theme";
import { Button } from "components/button";
import { Checkbox } from "components/forms/checkbox";
import { Contentset, Filter } from "types";
import { FC, useEffect, useMemo, useState } from "react";
import { filterOption } from "../dropdown-filter";
import { flattenTree } from "helpers/tree";
import { Form } from "react-bootstrap";
import { getSearchOptions } from "..";
import { Select } from "components/forms/select";
import { unescape } from "helpers/text-processing";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { useMediaQuery } from "react-responsive";
import { useRouter } from "next/router";
import classNames from "classnames";

import {
  FlatTreeItem,
  QuickFilterProps,
  QuickFilterTree,
  SelectOption,
} from "constants/types";
import { generateRoute, useSlugPath } from "helpers/routing";
import { getCountryAndLanguage, getLocale } from "helpers/locale/utils";
import { Tabs } from "components/tabs";

// TODO: fakeContentset and fakeFilter should be fetched from the API. If there are any changes to the
// contentset or filter in statamic they don't show up here and there might be a difference between
// the product searches used in the quickfilter and in the usual product page

const fakeContentset = {
  id: "contentset-0099999",
  _lang: "en",
  base_display_limit_for_products: "48",
  btn_call_to_action: true,
  category: "nv_products",
  dlc_api: null,
  dlc_sort: null,
  dlc_query: null,
  default_view: "small_tiles",
  default_view_changer: true,
  default_view_counter: true,
  default_view_option_counter: true,
  display_excerpt: true,
  display_eye_catcher: null,
  display_featured_logos: null,
  display_keyvisual: true,
  display_limit_increase: "24",
  display_publishing_date: false,
  display_subtitle: null,
  dropdown_size: "",
  entity_type: "kp",
  entity_display: true,
  family: [],
  filter_entity: '{"search":{"&":[{"family":"!kp_general_variant"}]}}',
  force_exact_entity_display_limit: false,
  glow_over_image: null,
  layout: "list",
  link_call_to_action: true,
  preview_image_background: null,
  preview_image_container_border: null,
  preview_image_framing: null,
  preview_image_padding: null,
  preview_image_width: null,
  show_more_entities_button: true,
  slider_type: null,
  slug: "products",
  sorter:
    '[{"attribute":"is_discontinued","order_value":"asc","type":"new_relation","enabled":true},{"attribute":"sorting_metric","order_value":"desc","type":"new_relation","enabled":true},{"attribute":"label","order_value":"asc","type":"new_relation","enabled":true}]',
  style_action_button: "transparent",
  tiles_orientation: null,
  tiles_per_row: null,
  trim_image_background: null,
  use_short_title: false,
  vertical_image_alignment: null,
  vertical_tile_alignment: null,
  show_preview_filter: null,
  filter: [
    {
      __typename: "Filter",
      id: "filter-148",
      title: "Product name (Att: title)",
      filter_label_source: "akeneo",
      filter_ui_element: "ui-41",
      accordeon_open: true,
      source_type: "attribute",
      filter_type: "single-value-dropdown",
      filter_category: "",
      filter_attribute: "search_alias",
      filter_statamic: "",
      limit_first_request: false,
      option_sort_alphabetical: true,
      has_child_filter: false,
      _relationship_options: "[]",
      filter_preview: "[]",
      has_preview: false,
      link_to_page: "",
      child_filter: null,
    } as Filter,
  ],
} as Contentset;

const fakeFilter = {
  __typename: "Filter",
  id: "filter-148",
  title: "Product name (Att: title)",
  filter_label_source: "akeneo",
  filter_ui_element: "ui-41",
  accordeon_open: true,
  source_type: "attribute",
  filter_type: "single-value-dropdown",
  filter_category: "",
  filter_attribute: "search_alias",
  filter_statamic: "",
  limit_first_request: false,
  option_sort_alphabetical: true,
  has_child_filter: false,
  _relationship_options: [],
  filter_preview: [],
  has_preview: false,
  link_to_page: "",
  child_filter: [],
  options: [],
  label: "Product name",
};

export const QuickFilter: FC<{
  quickfilter: QuickFilterProps;
}> = ({ quickfilter: { filterTree, tabLabels, baseLink } }) => {
  const flatTree = useMemo(
    () =>
      flattenTree({
        nodes: filterTree,
        childrenKey: "options",
      }),
    [filterTree],
  );

  const { language } = useLocale();
  const path = useSlugPath();
  const router = useRouter();
  const [
    productNamePlaceholder,
    showProducts,
    selectBtn,
    noOptionsMessage,
    levelProductFinder,
    searchCategoryBtn,
    searchProductNameBtn,
  ] = useLabels(
    ["ui-41", "Product name"],
    ["ui-615", "products"],
    ["ui-620", "Select"],
    ["ui-585", "No options"],
    ["ui-920", "3D Level Product Finder"],
    ["ui-926", "Search by category"],
    ["ui-927", "Search by product name"],
  );

  const getMobileOptions = (
    node?: FlatTreeItem<QuickFilterTree>,
  ): SelectOption[] => {
    return flatTree
      .filter((x) => x.parent === (node ? node.id : null))
      .map((option) => ({
        value: option.id,
        label: option.label,
      }));
  };

  const [node, setNode] = useState<FlatTreeItem<QuickFilterTree> | null>(null);
  const [selectedNode, setSelectedNode] =
    useState<FlatTreeItem<QuickFilterTree> | null>(null);
  const [mobileOptions, setMobileOptions] = useState<SelectOption[]>(
    getMobileOptions(),
  );

  // No spaces in chinese
  const isChinese = language === "zh";
  const showMoreLabel = isChinese
    ? `${showProducts.counter_prefix}${
        selectedNode?.matches ||
        node?.matches ||
        showProducts.counter_fraction_separator
      }${showProducts.counter_suffix}`
    : `${showProducts.counter_prefix} ${
        selectedNode?.matches ||
        node?.matches ||
        showProducts.counter_fraction_separator
      } ${showProducts.counter_suffix}`;

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.md - 1}px)`,
  });

  const { locale } = useLocale();

  const [searchOptions, setSearchOptions] = useState<SelectOption[]>([]);
  const [searchValue, setSearchValue] = useState<SelectOption>(null);
  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    const fetchOptions = async () => {
      const tmp = await getSearchOptions(
        fakeContentset,
        locale,
        "search_alias",
        {},
      );
      setSearchOptions(tmp);
    };
    void fetchOptions();
  }, [locale]);

  const getSelectedEntity = async () => {
    const body = JSON.stringify({
      pageIndex: 0,
      offset: 0,
      contentset: fakeContentset,
      locale,
      filterValues: {
        searchValue: { search_alias: searchValue },
      },
      paginate: false,
    });
    const response = await fetch(`/api/contentset`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body,
    });
    const data = await response.json();
    if (data?.entities?.[0]) {
      const url = data.entities[0]._url;
      const productLanguage = data.entities[0]._lang;

      const [country, language] = getCountryAndLanguage(locale);
      const routeLocale = getLocale(country, productLanguage ?? language);
      router.push(generateRoute(routeLocale, url, locale, path));
    }
  };

  useEffect(() => {
    if (searchValue) {
      void getSelectedEntity();
    }
  }, [searchValue]);

  const onNodeChange = (node?: FlatTreeItem<QuickFilterTree>) => {
    if (!node || node.hasChildren) {
      setNode(node);
      setMobileOptions(getMobileOptions(node));
    } else {
      setSelectedNode(node);
    }
  };

  return (
    <div className="quick-filter">
      <Tabs
        tabs={[
          {
            title: searchCategoryBtn.title,
            component: (
              <>
                {isMobile ? (
                  <Select
                    options={mobileOptions}
                    value={
                      selectedNode
                        ? ({
                            value: selectedNode.id,
                            label: selectedNode.label,
                          } as SelectOption)
                        : null
                    }
                    placeholder={`${selectBtn?.label} ${tabLabels?.[
                      node ? node.depth + 1 : 0
                    ]}`}
                    handleChange={(option) => {
                      if (option) {
                        const nextNode = flatTree.find(
                          (x) => x.id === option?.value,
                        );

                        onNodeChange(nextNode);

                        if (nextNode.hasChildren) {
                          setSelectedNode(null);
                        } else {
                          setSelectedNode(nextNode);
                        }
                      }
                    }}
                  />
                ) : (
                  <Form className="quick-filter-options">
                    {flatTree
                      .filter((x) => x.parent === (node ? node.id : null))
                      .map((option, index) => {
                        const isChecked = option.id === selectedNode?.id;
                        return (
                          <Checkbox
                            checked={isChecked}
                            className="quick-filter-option"
                            id={"quick-filter-" + option.label}
                            label={option.label}
                            supportLabel={
                              option.additionalLinkToPage && !node ? (
                                <>3D</>
                              ) : null
                            }
                            type="checkbox"
                            onChange={() => onNodeChange(option)}
                            key={index}
                          />
                        );
                      })}
                  </Form>
                )}
                <div className="flex-center quick-filter-nav">
                  <Button
                    variant={isMobile ? "small" : "white"}
                    icon={isMobile ? "caret-left" : "caret-left-small"}
                    className={classNames("quick-filter-nav-btn", {
                      hidden: !node && !selectedNode,
                    })}
                    label={"Back"}
                    onClick={() => {
                      if (selectedNode) {
                        setSelectedNode(node);
                      }

                      const parentNode = node?.parent
                        ? flatTree.find((x) => x.id === node.parent)
                        : null;

                      onNodeChange(parentNode);
                    }}
                    onlyIcon={isMobile}
                    disabled={!node && !selectedNode}
                  />
                  <div className="quick-filter-nav-links">
                    <Button
                      variant="primary"
                      label={showMoreLabel.replace("undefined", "")}
                      url={
                        "/" +
                        (flatTree?.find(
                          (option) =>
                            option.parent === (node?.id ?? null) &&
                            option?.id === selectedNode?.id,
                        )?.linkToPage || // selected node of this level
                          node?.linkToPage || // selected level
                          baseLink) // default
                      }
                    />
                    {node?.additionalLinkToPage ? (
                      <Button
                        variant="secondary"
                        label={unescape(levelProductFinder.title)}
                        url={"/" + node?.additionalLinkToPage}
                      />
                    ) : null}
                  </div>

                  <Button
                    variant={isMobile ? "small" : "white"}
                    icon={isMobile ? "caret-right" : "caret-right-small"}
                    className={classNames("quick-filter-nav-btn", {
                      hidden: !(selectedNode && selectedNode.hasChildren),
                    })}
                    label={"Forth"}
                    onClick={() => {
                      onNodeChange(selectedNode);
                      setSelectedNode(null);
                    }}
                    disabled={!(selectedNode && selectedNode.hasChildren)}
                    onlyIcon={isMobile}
                  />
                </div>
              </>
            ),
          },
          {
            title: searchProductNameBtn.title,
            component: (
              <>
                <div className="quick-filter-search">
                  <Select
                    options={searchOptions}
                    value={searchValue}
                    handleChange={setSearchValue}
                    externalInput={searchInput}
                    handleInputChange={setSearchInput}
                    width={250}
                    isSearch
                    isDropdownSearch
                    isClearable
                    placeholder={productNamePlaceholder?.title}
                    filterOption={filterOption}
                    noOptionsText={
                      searchInput?.length < 3
                        ? noOptionsMessage.label
                        : noOptionsMessage.title
                    }
                  />
                </div>
                <div className="flex-center quick-filter-nav">
                  <div className="quick-filter-nav-links">
                    <Button
                      variant="primary"
                      label={`${showProducts.counter_prefix} ${showProducts.counter_fraction_separator} ${showProducts.counter_suffix}`}
                      url={"/" + baseLink}
                    />
                  </div>
                </div>
              </>
            ),
          },
        ]}
        className="quick-filter-wrapper"
      />
    </div>
  );
};
